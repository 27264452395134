import React, { useState, useEffect, useRef, useCallback } from "react"

import Nav from "@components/nav/nav"
import IndexPage_FirstPromo from "./firstPromo/indexpage_firstPromo"
import IndexPage_Previewer from "./previewer/indexpage_previewer"
import IndexPage_V3 from "./freeVsPro/indexpage_freeVsPro"
import IndexPage_Why from "./why/indexpage_why.js"
import IndexPage_HowItWorks from "./indexpage_howitworks"
import IndexPage_Docs from "./indexpage_docs"
import IndexPage_Faq from "./indexpage_faq"
import IndexPage_Who from "./indexpage_who"
import IndexPage_Courses from "./indexpage_courses"
import IndexPage_Discounts from "./indexpage_discounts"
import IndexPage_CodeBar from "./codeBar/index"
import IndexPage_Reviews from "./reviews/indexpage_reviews"
import IndexPage_Gallery from "./gallery"
import IndexPage_Highlights from "./highlights"
import IndexPage_UserReviews from "./userReviews"
import IndexPage_Discord from "./discord"
import ModalSignIn from "./signin/modal_signin.js"
// import MessengerCustomerChat from 'react-messenger-customer-chat'

import { ModalSignInContext } from "./context"

import API from "../../common/api.js"

import { IndexpageRoot, LandingRow } from "./styles"
import theme from "@src/style/theme"
import { injectIntl } from "react-intl"

export default injectIntl(({ intl: { locale } }) => {
  const [code, setCode] = useState("")
  const [language, setLanguage] = useState("en_US")
  const [showSignIn, setShowSignIn] = useState(false)
  const [price, setPrice] = useState(undefined)

  useEffect(() => {
    setCode(API.getParameterByName("code"))
    if (locale === "ru") {
      setLanguage("ru_RU")
    }
  }, []) // componentDidMount

  return (
    <IndexpageRoot>
      <ModalSignInContext.Provider
        value={{
          setShowSignIn: setShowSignIn,
          setPrice: setPrice,
        }}
      >
        <Nav defaultIndex={0} code={code} membersArea />

        {code && <IndexPage_CodeBar code={code} />}

        <IndexPage_FirstPromo />

        <IndexPage_Gallery />

        <IndexPage_V3 />

        <IndexPage_UserReviews />

        <IndexPage_Discord />

        <IndexPage_Previewer />

        <IndexPage_Highlights />

        <IndexPage_Courses />

        <IndexPage_Reviews />

        <IndexPage_Faq />

        <IndexPage_Who />

        <ModalSignIn
          isOpen={showSignIn}
          setIsOpen={setShowSignIn}
          price={price}
        />
      </ModalSignInContext.Provider>
    </IndexpageRoot>
  )
})

// <MessengerCustomerChat
//         pageId='294462624407894'
//         appId='172888069977445'
//         themeColor={theme.colors.accent}
//         language={language}
//       />
