import React, { Fragment, useState, useEffect } from "react"
import API from "@src/common/api"
import { FormattedMessage, injectIntl } from "react-intl"
import BuyButtons from "../../buyButtons"
import { localizedUrl } from "@src/components/link"
import Linker from "@src/common/linker"
import config from "@src/common/config"
import { Link } from "gatsby"
import * as Style from "./styles"

import { FullscreenWrapper } from "../styles"
import * as GlobalStyle from "@src/style/globalStyle"
import * as CoreStyle from "@src/style/core"
import { StyledMessage, StyledMessages } from "@components/common/styledMessage"
import styled from "styled-components"
import { ModalSignInContext } from "@components/main/context"

import { FreeButton } from "@src/components/buttons"

const getDiscountPercentage = (discountPrice, fullPrice) => {
  return Math.round((1 - discountPrice / fullPrice) * 100)
}

export default injectIntl(({ intl: { locale }, code }) => {
  const intlExtras = {}

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    apiReady: false,
    discountPrice: "",
    fullPrice: "",
  })

  const loadingCallback = ({ loading }) => {
    setLoading(loading)
  }

  const componentDidMount = () => {
    const urlCode = API.getParameterByName("code")

    if (urlCode === null || urlCode === "") {
      setData({
        code: urlCode,
        loading: false,
        apiReady: true,
      })
      return
    }

    API.pricing(urlCode, loadingCallback)
      .then((response) => {
        const result = {
          apiReady: true,
          loading: false,
          fullPrice: response.fullPrice.price,
          code: urlCode,
        }
        if (response.discountPrice) {
          result.discountPrice = response.discountPrice.price
          result.codeInactive = response.codeInactive
        }
        setData(result)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(componentDidMount, [])

  return (
    <FullscreenWrapper>
      <GlobalStyle.LandingRow codeBar>
        <GlobalStyle.LandingRowContent paddingTop>
          <Style.OfferRow>
            <Style.Offer>
              <Style.H4>
                {loading && (
                  <StyledMessage id="buy_buttons.fetching_discounts" />
                )}

                {!loading && (
                  <StyledMessage
                    id="index.offer.headline"
                    values={{
                      code: data.code,
                      price: data.discountPrice,
                    }}
                  />
                )}
              </Style.H4>
              <Style.P>
                {!loading && (
                  <StyledMessage
                    id="index.offer.message"
                    values={{
                      save: Math.round((data.fullPrice - data.discountPrice) * 100) / 100,
                      percentage: getDiscountPercentage(
                        data.discountPrice,
                        data.fullPrice
                      ),
                    }}
                  />
                )}
              </Style.P>
            </Style.Offer>

            {!loading && (
              <ModalSignInContext.Consumer>
                {({ setShowSignIn, setPrice }) => {
                  return (
                    <Style.BuyButton
                      onClick={() => {
                        setPrice(`${data.discountPrice}€`)
                        setShowSignIn(true)
                      }}
                    >
                      <StyledMessage id="buy" />
                    </Style.BuyButton>
                  )
                }}
              </ModalSignInContext.Consumer>
            )}
          </Style.OfferRow>
        </GlobalStyle.LandingRowContent>
      </GlobalStyle.LandingRow>
    </FullscreenWrapper>
  )
})
