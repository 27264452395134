import React from 'react'
import { injectIntl } from 'react-intl'
import { StyledMessage, StyledMessages } from '@components/common/styledMessage'
import { H2, BlueButton } from './styles'
import config from '@src/common/config'
import {
  LandingRow,
  LandingRowContent,
  LandingRowTextBlock
} from '@src/style/globalStyle'
import { localizedUrl } from '@src/components/link'
import Linker from '@src/common/linker'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default injectIntl(({ intl, activation }) => {
  const { locale } = intl
  const intlExtras = {
    free: (...msg) => (
      <a
        key={msg}
        href='#'
        onClick={() => {
          config.mailerlite.showFreeForm(locale, trackCustomEvent)
        }}
      >
        {msg}
      </a>
    ),
    readfaq: (...msg) => (
      <BlueButton
        key={msg}
        onClick={() => {
          Linker.openUrl(localizedUrl('/blog/arcpanel/2019-08-30-faq', locale))
        }}
      >
        {msg}
      </BlueButton>
    )
  }

  return (
    <LandingRow white>
      <LandingRowContent>
        <LandingRowTextBlock>
          <H2>
            <StyledMessage id='faq' />
          </H2>
          {activation ? (
            <StyledMessages id='activation.faq' values={intlExtras} />
          ) : (
            <StyledMessages id='index.faq' values={intlExtras} />
          )}
        </LandingRowTextBlock>
      </LandingRowContent>
    </LandingRow>
  )
})
