import styled, { css } from "styled-components"
import { device, core } from "@src/style"
import { FullscreenWrapper } from "../styles"
import { GatsbyImage } from "gatsby-plugin-image"

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;

  @media ${device.laptopL} {
    flex-basis: 60%;
  }
`

export const ImageBlock = styled.div`
  flex-basis: 50%;
  margin: 1rem 0rem;

  @media ${device.laptop} {
    margin: 0;
  }

  @media ${device.laptopL} {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const TextImageBlock = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${device.laptop} {
    flex-direction: row;
    justify-content: space-around;
    margin: 1rem 0;

    ${({ left }) =>
      left &&
      css`
        ${ImageBlock} {
          margin-right: 2rem;
        }
      `}

    ${({ right }) =>
      right &&
      css`
        ${ImageBlock} {
          margin-left: 2rem;
        }
      `}
  }
`
