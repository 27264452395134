import styled, { css } from "styled-components"
import * as GlobalStyle from "@src/style/globalStyle"
import { device, core } from "@src/style"
import { WhiteButton } from "@components/buttons/style"

export const OfferRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Offer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const H4 = styled(core.H4)`
  font-weight: 700;
  color: ${(props) => props.theme.offer.textColor};
`
export const P = styled(core.PM)`
  margin-top: 16px;
  color: ${(props) => props.theme.offer.subTextColor};
`

export const BuyButton = styled(WhiteButton)`
  margin-top: 24px;
  @media ${device.tablet} {
    margin: 0;
  }
`
