import React, { useState, useEffect, useRef, useCallback } from "react"
import IndexPage_Header from "./indexpage_header.js"
import { Previewer } from "../styles"

import { PreviewerContext } from "./context.js"

const range = (start, end) => {
  const length = end - start
  return Array.from({ length }, (_, i) => start + i)
}
const getMaskImage = (maskType) => {
  if (maskType) {
    return `/maskso/_${maskType}.jpg`
  } else {
    return `/maskso/_main.jpg`
  }
}
const preCacheImages = () => {
  //pre cache images
  ;[
    "luminosity",
    "saturation",
    "bl",
    "gl",
    "rl",
    ...["darks", "lights", "mids"]
      .map((level) => range(1, 7).map((index) => `${level}${index}`))
      .flat(),
    ...range(0, 11).map((index) => `zone${index}`),
  ].forEach((maskType) => {
    new Image().src = getMaskImage(maskType)
  })
}

export default ({ preview }) => {
  const previewerRef = useRef(null)
  const setMaskType = (maskType) => {
    previewerRef.current.style["background-image"] = `url('${getMaskImage(
      maskType
    )}')`
  }

  return (
    <PreviewerContext.Provider
      value={{
        setMaskType: setMaskType,
        preview: true,
      }}
    >
      <Previewer id="previewer" ref={previewerRef}>
        <IndexPage_Header />
      </Previewer>
    </PreviewerContext.Provider>
  )
}
