import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import BuyButtons from '../buyButtons'
import { Link } from 'gatsby'
import {
  FullscreenWrapper,
  LeftColumn,
  RightColumn,
  H2,
  H3,
  PM,
  BlueButton,
  RoundImageContainer,
  RoundImage
} from './styles'
import {
  LandingRow,
  LandingRowContent,
  RowMobileCol
} from '@src/style/globalStyle'

import { printMessage } from '@src/style/intlStyler'

export default injectIntl(({ intl }) => {
  const intlExtras = {
    github: (...msg) => (
      <a key={msg} target='_blank' href='https://github.com/AAverin'>
        {msg}
      </a>
    ),
    facebook: (...msg) => (
      <a
        key={msg}
        href='https://www.facebook.com/AntonAverinPhotographyPage'
        target='_blank'
      >
        {msg}
      </a>
    ),
    email: (...msg) => (
      <a key={msg} target='_blank' href='mailto:anton.averin.photo@gmail.com'>
        {msg}
      </a>
    )
  }
  return (
    <LandingRow grey>
      <LandingRowContent>
        <H2>
          <FormattedMessage id='who' />
        </H2>
        <RowMobileCol reverseCol>
          <LeftColumn>
            {intl.messages['index.who'].map((message, index) => {
              return (
                <span key={index}>
                  {printMessage(intl, message, index, intlExtras)}
                </span>
              )
            })}
          </LeftColumn>
          <RoundImageContainer>
            <RoundImage>
              <img alt='Anton Averin Photo' src='/res/aaverin.jpg' />
            </RoundImage>
          </RoundImageContainer>
        </RowMobileCol>
      </LandingRowContent>
    </LandingRow>
  )
})
