import styled, { css } from "styled-components"
import { device, core } from "@src/style"
import { FullscreenWrapper } from "../styles"
import { SmallBlueButton } from "@components/buttons/style"
import * as MainStyles from "../styles"

export const ReviewFormWrapper = styled.div`
  position: relative;
  margin: 1rem 0;
`

export const Close = styled.a`
  position: absolute;
  right: 24px;
  top: 18px;
  width: 16px;
  height: 16px;
  z-index: 999;
  color: #dbdbdbaa;

  &:hover {
    cursor: pointer;
    color: #dbdbdb;
  }
`

export const Form = styled.form`
  height: 460px;

  @media ${device.tablet} {
    height: 400px;
  }

  @media ${device.only.mobileML} {
    p:first-child {
      width: 310px;
      text-align: left;
    }
  }

  @media ${device.only.mobileS} {
    p:first-child {
      width: 250px;
      text-align: left;
    }
  }
`

export const FormHeadline = styled(MainStyles.PXS)`
  font-weight: 500;
  padding-top: 1rem;
`

export const FormField = styled.input`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.form.input.border};
  @media ${device.tablet} {
    width: 50%;
  }
`

export const FormText = styled.textarea`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.form.input.border};
  @media ${device.tablet} {
    width: 50%;
  }
`

export const FormResult = styled.div`
  margin-top: 1rem;
`

export const Slider = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  margin: 2rem 0 1rem;

  @media ${device.laptop} {
    justify-content: flex-start;
  }

  @media ${device.desktop} {
    ${(props) => props.theme.widthLimiter}
    margin: 1rem auto 0rem auto;
  }
`

export const Reviewer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Review = styled.div`
  overflow: auto;
  height: 200px;
  padding: 0 1rem;
`

export const Card = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 1rem 0rem;
  width: 100%;

  @media ${device.tablet} {
    width: 49%;
  }
  @media ${device.laptop} {
    width: 48%;
    margin-right: 1rem;
  }
  @media ${device.laptopL} {
    width: 23%;
  }

  transition: transform 0.2s !important;
  &:hover {
    background: #fff5f0;
  }
`

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media ${device.laptop} {
    flex-direction: row;
  }
`

export const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 1rem;

  @media ${device.laptop} {
    margin: 0;
  }
`

export const LeaveReviewButton = styled(SmallBlueButton)`
  width: 150px;
`
