import React from 'react'
import { injectIntl } from 'react-intl'
import { FullscreenWrapper } from '../styles'
import * as Styles from './styles'
import * as GlobalStyle from '@src/style/globalStyle'
import * as MainStyles from '../styles'
import { LongBlueButton, LongYellowButton } from '@components/buttons/style'
import { StaticImage } from 'gatsby-plugin-image'
import {
  StyledMessage,
  StyledMessages
} from '@src/components/common/styledMessage'
import { graphql, useStaticQuery } from 'gatsby'
import { localizedUrl } from '@src/components/link'
import Linker from '@src/common/linker'

export default injectIntl(({ intl }) => {
  return (
    <FullscreenWrapper>
      <GlobalStyle.LandingRow>
        <GlobalStyle.LandingRowContent>
          <MainStyles.H2>
            <StyledMessage id='index.features.headline' />
          </MainStyles.H2>

          <MainStyles.H3>
            <StyledMessage id='index.features.masks.headline' />
          </MainStyles.H3>
          <Styles.TextImageBlock right>
            <Styles.TextBlock>
              <StyledMessages id='index.features.masks' />
              <GlobalStyle.CenterColumn
                style={{
                  paddingTop: '2rem'
                }}
              >
                <LongBlueButton
                  onClick={() => {
                    Linker.openUrl(
                      localizedUrl('/luminosity-masking-basics', intl.locale)
                    )
                  }}
                >
                  <StyledMessage id='index.course' />
                </LongBlueButton>
              </GlobalStyle.CenterColumn>
            </Styles.TextBlock>
            <Styles.ImageBlock>
              <StaticImage alt='' src='./img/1LuminosityMasks.jpg' />
            </Styles.ImageBlock>
          </Styles.TextImageBlock>

          <MainStyles.H3>
            <StyledMessage id='index.features.anyrange.headline' />
          </MainStyles.H3>
          <Styles.TextImageBlock right>
            <Styles.TextBlock>
              <StyledMessages id='index.features.anyrange' />
              <GlobalStyle.CenterColumn
                style={{
                  paddingTop: '2rem'
                }}
              >
                <LongYellowButton
                  onClick={() => {
                    Linker.openUrl(
                      localizedUrl('/blog/2021-06-12-arcpanel-pro', intl.locale)
                    )
                  }}
                >
                  <StyledMessage id='arcpanel_pro' />
                </LongYellowButton>
              </GlobalStyle.CenterColumn>
            </Styles.TextBlock>

            <Styles.ImageBlock>
              <StaticImage alt='' src='./img/2AnyRange.jpg' />
            </Styles.ImageBlock>
          </Styles.TextImageBlock>

          <MainStyles.H3>
            <StyledMessage id='index.features.infinite.headline' />
          </MainStyles.H3>
          <Styles.TextImageBlock right>
            <Styles.TextBlock>
              <StyledMessages id='index.features.infinite' />
            </Styles.TextBlock>
            <Styles.ImageBlock>
              <StaticImage alt='' src='./img/3InfiniteColor.jpg' />
            </Styles.ImageBlock>
          </Styles.TextImageBlock>
        </GlobalStyle.LandingRowContent>
      </GlobalStyle.LandingRow>
    </FullscreenWrapper>
  )
})
