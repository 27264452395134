import React, { useState } from "react"
import { injectIntl } from "react-intl"
import { FullscreenWrapper } from "../styles"
import * as Styles from "./styles"
import * as GlobalStyle from "@src/style/globalStyle"
import * as MainStyles from "../styles"
import { graphql, useStaticQuery } from "gatsby"
import Linker from "@src/common/linker"
import { StyledMessage } from "@src/components/common/styledMessage"
import { SmallBlueButton } from "@components/buttons/style"
import CloseIcon from "@material-ui/icons/Close"
import API from "@src/common/api.js"

import { shuffleArray } from "@src/common/utils"
import { useEffect } from "react"

export default injectIntl(({ intl }) => {
  const { locale } = intl
  const [doReview, setDoReview] = useState(false)
  const [readAll, setReadAll] = useState(false)
  const [hasMoreReviews, setHasMoreReviews] = useState(false)

  const [localeReviews, setLocaleReviews] = useState([])
  const [shortReviews, setShortReviews] = useState([])

  const reviews = useStaticQuery(
    graphql`
      {
        allReviewsJson {
          edges {
            node {
              en {
                link
                name
                instagram
                text
              }
              ru {
                link
                name
                instagram
                text
              }
            }
          }
        }
      }
    `
  )

  useState(() => {
    if (!reviews) return
    const _localeReviews = reviews.allReviewsJson.edges[0].node[locale]
    setLocaleReviews(_localeReviews)

    const _shortReviews = _localeReviews.slice(0, 20)
    setShortReviews(_shortReviews)

    setHasMoreReviews(_localeReviews.length > _shortReviews.length)
  }, [reviews])

  const cardsReviews = readAll ? localeReviews : shortReviews

  if (!cardsReviews) return <></>

  return (
    <MainStyles.FullscreenWrapper>
      <GlobalStyle.LandingRow grey>
        <GlobalStyle.LandingRowContent>
          <MainStyles.H2>
            <StyledMessage id="index.user.reviews" />
          </MainStyles.H2>
          <>
            <AllReviews reviews={cardsReviews} />
            <GlobalStyle.LandingRowContent>
              <Styles.ButtonsRow>
                {hasMoreReviews && (
                  <Styles.ActionButton
                    onClick={() => {
                      setReadAll(!readAll)
                    }}
                  >
                    {readAll ? (
                      <StyledMessage id="index.user.review.less" />
                    ) : (
                      <StyledMessage id="index.user.review.more" />
                    )}
                  </Styles.ActionButton>
                )}
                <Styles.LeaveReviewButton
                  onClick={() => {
                    setDoReview(!doReview)
                  }}
                >
                  {doReview ? (
                    <StyledMessage id="index.user.review.cancelreview" />
                  ) : (
                    <StyledMessage id="index.user.review.leavereview" />
                  )}
                </Styles.LeaveReviewButton>
              </Styles.ButtonsRow>
            </GlobalStyle.LandingRowContent>
          </>
          {doReview && (
            <Styles.ReviewFormWrapper>
              <ReviewForm />
            </Styles.ReviewFormWrapper>
          )}
        </GlobalStyle.LandingRowContent>
      </GlobalStyle.LandingRow>
    </MainStyles.FullscreenWrapper>
  )
})

const AllReviews = injectIntl(({ intl, reviews }) => {
  return (
    <Styles.Slider>
      {reviews.map((review) => {
        return (
          <Styles.Card key={review.link}>
            <Styles.Reviewer>
              {review.instagram && (
                <a
                  target="_blank"
                  href={`https://www.instagram.com/${review.instagram}`}
                >
                  {review.name}
                </a>
              )}
              {!review.instagram && (
                <>
                  {review.name}
                  <br />
                </>
              )}

              <a href={`${review.link}`} target="_blank">
                {review.link}
              </a>
            </Styles.Reviewer>
            <br />
            <Styles.Review>{review.text}</Styles.Review>
          </Styles.Card>
        )
      })}
    </Styles.Slider>
  )
})

const ReviewForm = injectIntl(({ intl }) => {
  const [email, setEmail] = useState()
  const [review, setReview] = useState()
  const [link, setLink] = useState()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSubmit = async (e) => {
    e.preventDefault()
    const body = {
      email: email,
      review: review,
      link: link,
    }

    await API.postReview(body, ({ loading }) => {
      setLoading(loading)
    })
    setSuccess(true)
  }

  return (
    <Styles.Form onSubmit={onSubmit}>
      <MainStyles.PXS>
        <StyledMessage
          id="index.user.reviews.intro"
          values={{
            mail: (...msg) => (
              <a href="mailto: anton.averin.photo@gmail.com">
                anton.averin.photo@gmail.com
              </a>
            ),
          }}
        />
      </MainStyles.PXS>

      <Styles.FormHeadline>
        <StyledMessage id="index.user.reviews.email.headline" />
      </Styles.FormHeadline>
      <MainStyles.PXS>
        <StyledMessage id="index.user.reviews.email" />
      </MainStyles.PXS>
      <Styles.FormField
        required={true}
        type="email"
        onChange={(e) => {
          setEmail(e.target.value)
        }}
      ></Styles.FormField>
      <Styles.FormHeadline>
        <StyledMessage id="index.user.reviews.review.headline" />
      </Styles.FormHeadline>
      <MainStyles.PXS>
        <StyledMessage id="index.user.reviews.review" />
      </MainStyles.PXS>
      <Styles.FormText
        required={true}
        onChange={(e) => {
          setReview(e.target.value)
        }}
      ></Styles.FormText>
      <Styles.FormHeadline>
        <StyledMessage id="index.user.reviews.link.headline" />
      </Styles.FormHeadline>
      <MainStyles.PXS>
        <StyledMessage id="index.user.reviews.link" />
      </MainStyles.PXS>

      <Styles.FormField
        required={true}
        onChange={(e) => {
          setLink(e.target.value)
        }}
      ></Styles.FormField>
      <Styles.FormResult>
        {!loading && (
          <>
            {!success && (
              <SmallBlueButton type="submit">
                <StyledMessage id="index.user.reviews.send" />
              </SmallBlueButton>
            )}
            {success && <StyledMessage id="index.user.reviews.success" />}
          </>
        )}
        {loading && <StyledMessage id="index.user.reviews.loading" />}
      </Styles.FormResult>
    </Styles.Form>
  )
})
