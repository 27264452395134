import styled, { css } from "styled-components"
import * as GlobalStyle from "@src/style/globalStyle"
import { device, core } from "@src/style"

export const Headline = styled(core.H3)`
  margin: 0 0 1rem 0;
  text-align: center;
`

export const Cell = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.4rem 0.6rem;
  overflow: hidden; // Or flex might break
  list-style: none;
  border: solid 3px white;
  background-color: ${(props) => props.theme.main.rows.grey.bg};

  p:nth-child(2) {
    color: ${(props) => props.theme.main.subTextColor};
  }

  @media ${device.tablet} {
    padding: 0.8em 1.2em;
  }

  ${(props) =>
    props.transparent &&
    css`
      background-color: transparent;
      border: solid 3px transparent;
    `}

  ${(props) =>
    props.center &&
    css`
      justify-content: center;
      align-items: center;
    `}

    ${(props) =>
      props.short &&
      css`
        width: 5% !important;
        overflow: hidden;
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 10px;
      `}
`

export const TableRow = styled(GlobalStyle.Row)`
  flex-wrap: wrap;

  p {
    text-align: left;
  }
`

export const Grid = styled.div`

  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 0 16px 0;
  width: 100%;
  display: flex;

  ${Cell} {
    width: 60%;
  }
}
`

export const List = styled.ul`
  li {
  }
`

export const CardsRow = styled(GlobalStyle.LandingRowContent)`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }
`

export const Card = styled.div`
  cursor: default;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0 1rem 1rem 1rem;
  width: 700px;
  transition: transform 0.2s !important;
  @media ${device.tablet} {
    margin-bottom: 0px;
    width: 900px;
  }
  &:hover {
    background: #fff5f0;
    transform: scale(0.99);
  }

  ${({ pro, theme }) =>
    pro &&
    css`
      border: 1px solid ${theme.colors.accent};
    `}
`

export const CenterColumn = styled(GlobalStyle.CenterColumn)`
  padding-top: 2rem;
`

export const CardContent = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const CardTop = styled.div``
export const CardBottom = styled.div``

export const ButtonsRow = styled(GlobalStyle.Row)`
  width: 80%;
  justify-content: space-evenly;
  padding-bottom: 1rem;
  flex-wrap: wrap;

  div {
    margin-bottom: 1rem;
  }

  @media ${device.tablet} {
    div {
      margin-bottom: 0;
    }
  }
`
