import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import YouTube from '@components/youtube'

import { FullscreenWrapper, H2, VideoSlider } from '../styles'

import { LandingRow, LandingRowContent } from '@src/style/globalStyle'

export default injectIntl(({ intl: { locale } }) => {
  const settings = {
    dots: true,
    centerMode: true,
    slidesToShow: 1,
    centerPadding: '200px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          centerPadding: '100px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          centerMode: true,
          centerPadding: '100px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <FullscreenWrapper>
      <LandingRow black>
        <LandingRowContent>
          <H2>
            <FormattedMessage id='why' />
          </H2>
        </LandingRowContent>
        <VideoSlider {...settings}>
          <YouTube video='son2RrM1JX4' start='20' />
          <YouTube video='QlbbA71fXoo' start='70' />
          <YouTube video='4O5UHhJueEk' start='8' />
          <YouTube video='qVqYpKkVydU' start='241' />
          <YouTube video='IAEwNvT9Mxc' start='505' />
          <YouTube video='JMTvzR35-kE' start='325' />
        </VideoSlider>
      </LandingRow>
    </FullscreenWrapper>
  )
})
