import styled, { css } from "styled-components"
import { device, core } from "@src/style"
import { FullscreenWrapper } from "../styles"
import { GatsbyImage } from "gatsby-plugin-image";

export const GalleryWrapper = styled(FullscreenWrapper)``

export const Author = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 15%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: underline;
    color: white;
    &:hover {
      color: #FE5E03;
    }
  }
`

export const ImageWrapper = styled.div`
  height: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  position: relative;

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
    z-index: 999;

    ${Author} {
      opacity: 0.5;
    }
  }
`

export const Image = styled(GatsbyImage)``

export const Masonry = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;
`
