import React from 'react'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import BuyButtons from '../../buyButtons'
import { LocalizedLink, localizedUrl } from '@src/components/link'
import Linker from '@src/common/linker'
import config from '@src/common/config'
import { Link } from 'gatsby'
import { FullscreenWrapper, H2, H3, PM } from '../styles'
import * as Style from './styles'
import * as CoreStyle from '@src/style/core'
import {
  BlueButton,
  TinyBlueButton,
  YellowButton
} from '@components/buttons/style'
import * as GlobalStyle from '@src/style/globalStyle'
import { ButtonsColumn, PhotoshopRequired } from '../../buyButtons/style'
import { StyledMessage, StyledMessages } from '@components/common/styledMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import {
  FreeButton,
  InstallButton,
  PurchaseButton,
  SimplePurchaseButton
} from '@src/components/buttons'
import { intlStyler, printMessage } from '@src/style/intlStyler'
import { SocialBlock, SocialIcon } from '@src/style/globalStyle'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

import theme from '@src/style/theme'

const extras = {
  check: msg => {
    return (
      <FontAwesomeIcon
        color={msg == 1 ? theme.colors.check : theme.colors.textColor}
        icon={faCheckCircle}
        size='1x'
      />
    )
  },
  uncheck: (...msg) => <></>,
  row: (...msg) => <Style.TableRow key={msg}>{msg}</Style.TableRow>,
  teaching: (...msg) => {
    const intl = useIntl()
    const url = '/blog/2022-11-13-arcpanel-free-for-education/'
    return (
      <TinyBlueButton
        onClick={() => {
          if (url) Linker.openUrl(localizedUrl(url, intl.locale))
        }}
      >
        <StyledMessage id='read_more' />
      </TinyBlueButton>
    )
  },
  youtube: (...id) => {
    return (
      <SocialIcon
        dark={true}
        href={`https://www.youtube.com/watch?v=${id}`}
        target='_blank'
      >
        <FontAwesomeIcon icon={faYoutube} size='xl' />
      </SocialIcon>
    )
  },
  tutorial: kindArray => {
    const intl = useIntl()
    var url
    const kind = kindArray ? kindArray[0] : undefined
    switch (kind) {
      case 'masks':
        url =
          '/blog/2020-02-02-luminosity-masking-basics-02-masks-from-basic-to-luminosity/'
        break
      case 'lights':
        url =
          '/blog/2020-02-04-luminosity-masking-basics-04-lights-masks-example/'
        break
      case 'darks':
        url =
          '/blog/2020-02-05-luminosity-masking-basics-05-dark-masks-example/'
        break
      case 'mids':
        url =
          '/blog/2020-02-06-luminosity-masking-basics-06-midtone-masks-examples/'
        break
      case 'infinitecolor':
        url = '/blog/2020-07-18-infinite-color/'
        break
    }
    return (
      <TinyBlueButton
        onClick={() => {
          if (url) Linker.openUrl(localizedUrl(url, intl.locale))
        }}
      >
        <StyledMessage id='tutorial' />
      </TinyBlueButton>
    )
  }
}

export default injectIntl(({ intl }) => {
  const entries = intl.messages['index.vs']
  return (
    <FullscreenWrapper>
      <GlobalStyle.LandingRow white>
        <Style.CardsRow paddingTop>
          <Style.Grid>
            <Style.Cell center transparent style={{ order: 1 }}></Style.Cell>
            <Style.Cell style={{ order: 2 }}>
              <StyledMessage id='index.price' />
            </Style.Cell>
            {intl.messages['index.vs'].map((it, index) => (
              <Style.Cell style={{ order: index + 3 }} key={index + 3}>
                {printMessage(intl, it, index, extras)}
              </Style.Cell>
            ))}
            <Style.Cell
              center
              transparent
              style={{ order: entries.length + 4 }}
            ></Style.Cell>
            {/*  */}
            <Style.Cell short center style={{ order: 1 }}>
              <StyledMessage id='free' />
            </Style.Cell>
            <Style.Cell short center transparent style={{ order: 2 }}>
              <StyledMessage id='free' />
            </Style.Cell>
            {intl.messages['index.vs.free'].map((it, index) => (
              <Style.Cell
                short
                center
                transparent
                style={{ order: index + 3 }}
                key={index + 3}
              >
                {printMessage(intl, it, index, extras)}
              </Style.Cell>
            ))}
            {/*  */}
            <Style.Cell short center style={{ order: 1 }}>
              Pro
            </Style.Cell>
            <Style.Cell short center transparent style={{ order: 2 }}>
              39.95€
            </Style.Cell>
            {intl.messages['index.vs.pro'].map((it, index) => (
              <Style.Cell
                short
                center
                transparent
                style={{ order: index + 3 }}
                key={index + 3}
              >
                {printMessage(intl, it, index, extras)}
              </Style.Cell>
            ))}
          </Style.Grid>
        </Style.CardsRow>
        <GlobalStyle.LandingRowContent>
          <Style.CenterColumn>
            <Style.ButtonsRow>
              <FreeButton noAdobe v3={true} />
              <SimplePurchaseButton />
            </Style.ButtonsRow>
            <CoreStyle.PXS>
              <StyledMessages
                id='buy_buttons.photoshop_required_v3'
                values={{
                  arcpanel22: (...msg) => (
                    <LocalizedLink
                      to='/blog/2021-05-05-arcpanel-2.2-sunset/'
                      locale={intl.locale}
                    >
                      {msg}
                    </LocalizedLink>
                  )
                }}
              />
            </CoreStyle.PXS>
          </Style.CenterColumn>
        </GlobalStyle.LandingRowContent>
      </GlobalStyle.LandingRow>
    </FullscreenWrapper>
  )
})
