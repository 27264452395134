import React from 'react'
import { injectIntl } from 'react-intl'
import { StyledMessage, StyledMessages } from '@components/common/styledMessage'
import { H2, BlueButton } from './styles'
import config from '@src/common/config'
import {
  LandingRow,
  LandingRowContent,
  LandingRowTextBlock
} from '@src/style/globalStyle'
import { localizedUrl } from '@src/components/link'
import Linker from '@src/common/linker'

export default injectIntl(({ intl }) => {
  const { locale } = intl
  const intlExtras = {
    discounts: (...msg) => (
      <BlueButton
        key={msg}
        onClick={() => {
          Linker.openUrl(localizedUrl('/blog/arcpanel/discounts/2019-03-26-how-to-get-50-percent-discount', locale))
        }}
      >
        {msg}
      </BlueButton>
    )
  }

  return (
    <LandingRow>
      <LandingRowContent>
        <LandingRowTextBlock>
          <H2>
            <StyledMessage id='discounts' />
          </H2>
          <StyledMessages id='index.discounts' values={intlExtras} />
        </LandingRowTextBlock>
      </LandingRowContent>
    </LandingRow>
  )
})
