import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import BuyButtons from '../../buyButtons'
import { localizedUrl } from '@src/components/link'
import Linker from '@src/common/linker'
import config from '@src/common/config'
import { Link } from 'gatsby'
import * as Style from './styles'
import { BlueButton, YellowButton } from '@components/buttons/style'
import * as GlobalStyle from '@src/style/globalStyle'
import * as CoreStyle from '@src/style/core'
import { ButtonsColumn, PhotoshopRequired } from '../../buyButtons/style'
import { StyledMessage, StyledMessages } from '@components/common/styledMessage'
import styled from 'styled-components'

import FirstPromo from './firstPromo.jpg'
import { FreeButton, InstallButton } from '@src/components/buttons'

export default injectIntl(({ intl: { locale } }) => {
  const intlExtras = {}

  return (
    <Style.FullscreenWrapper>
      <GlobalStyle.LandingRow firstPromo>
        <GlobalStyle.LandingRowContent paddingTop>
          <GlobalStyle.On tablet laptop>
            <GlobalStyle.Row>
              <Style.HalfBlock>
                <Style.H2>
                  <StyledMessage id='index.promo.headline' />
                </Style.H2>
                <Style.PL>
                  <StyledMessage id='index.promo.message' />
                </Style.PL>
                <GlobalStyle.Column>
                  <FreeButton v3={true} />
                </GlobalStyle.Column>
              </Style.HalfBlock>
              <Style.HalfBlock>
                <Style.PromoImage src={FirstPromo} />
              </Style.HalfBlock>
            </GlobalStyle.Row>
          </GlobalStyle.On>
          <GlobalStyle.On mobile>
            <GlobalStyle.Column>
              <Style.PromoImage src={FirstPromo} />
              <Style.H2>
                <StyledMessage id='index.promo.headline' />
              </Style.H2>
              <Style.PL>
                <StyledMessage id='index.promo.message' />
              </Style.PL>
              <FreeButton v3={true} />
            </GlobalStyle.Column>
          </GlobalStyle.On>
        </GlobalStyle.LandingRowContent>
      </GlobalStyle.LandingRow>
      {/* <>
        <Style.BlackFridayRow black>
          <div>
            <Style.Text>
              <StyledMessage id='black.friday.1' />
            </Style.Text>
          </div>
        </Style.BlackFridayRow>
        <Style.BlackFridayRow red>
          <div>
            <Style.Text>
              <StyledMessage id='black.friday.2' />
            </Style.Text>
          </div>
        </Style.BlackFridayRow>
      </> */}
      <>
        <Style.UkraineRow blue>
          <div>
            <Style.Text>
              <StyledMessage
                id='ukraine.1'
                values={{
                  support_ukraine: (...msg) => (
                    <a
                      href='https://supportukrainenow.org/donate'
                      target='_blank'
                    >
                      <StyledMessage id='support.ukraine' />
                    </a>
                  )
                }}
              />
            </Style.Text>
          </div>
        </Style.UkraineRow>
        <Style.UkraineRow yellow>
          <div>
            <Style.Text>
              <StyledMessage id='ukraine.2' />
            </Style.Text>
          </div>
        </Style.UkraineRow>
      </>
    </Style.FullscreenWrapper>
  )
})
