import React, { Fragment } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { graphql, useStaticQuery } from "gatsby"

import {
  FullscreenWrapper,
  H2,
  CardH3,
  PXS,
  Card,
  Video,
  Docs,
  DocsRow,
  DocsMessage,
} from "./styles"
import { LandingRow, LandingRowContent, PostBody } from "@src/style/globalStyle"
import { printMessage } from "@src/style/intlStyler"
import { GatsbyImage } from "gatsby-plugin-image";
import { localizedUrl } from "@src/components/link"
import Linker from "@src/common/linker"

import { VideoPlayer } from "@components/video"

export default injectIntl(({ intl }) => {
  const intlExtras = {}
  return (
    <FullscreenWrapper>
      <LandingRow>
        <LandingRowContent>
          <H2>
            <FormattedMessage id="how_it_works.title" />
          </H2>

          <Docs>
            <DocsRow>
              <DocsMessage>
                {intl.messages["index.how"].map((message, index) => {
                  return (
                    <span key={index}>
                      {printMessage(intl, message, index, intlExtras)}
                    </span>
                  )
                })}
              </DocsMessage>
              <Video>
                <VideoPlayer videoSrc="/res/ARCPanelFeatures_small.mov" />
              </Video>
            </DocsRow>
          </Docs>
        </LandingRowContent>
      </LandingRow>
    </FullscreenWrapper>
  )
})
