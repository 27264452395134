import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import ARCMask from '@components/uxppanel/arcmask/arcMask'
import ARCRetouch from '@components/uxppanel/arcretouch/arcretouch'
import ARCSharp from '@components/uxppanel/arcsharp/arcsharp'
import BuyButtons from '../buyButtons'
import config from '@src/common/config'
import { FullscreenWrapper, LeftColumn, RightColumn, H2, PM } from './styles'
import { printMessage } from '@src/style/intlStyler'
import {
  LandingRow,
  LandingRowContent,
  On,
  Row,
  CenterColumn
} from '@src/style/globalStyle'
import { LocalizedLink } from '@src/components/link'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { PanelGrid } from '@src/components/uxppanel/common/panelGrid'

export default injectIntl(({ intl }) => {
  const intlExtras = {
    free: (...msg) => (
      <a
        key={msg}
        href='#'
        onClick={() => {
          config.mailerlite.showFreeForm(intl.locale, trackCustomEvent)
        }}
      >
        {msg}
      </a>
    ),
    docs: (...msg) => <LocalizedLink to='/docs'>{msg}</LocalizedLink>,
    tutorials: (...msg) => (
      <a key={msg} href='#'>
        {msg}
      </a>
    ),
    blog: (...msg) => <LocalizedLink to='/blog'>{msg}</LocalizedLink>
  }

  const LeftText = () =>
    intl.messages['index.docs.left'].map((message, index) => {
      return printMessage(intl, message, index, intlExtras)
    })

  const RightText = () => {
    const message = config.settings.blog
      ? 'index.docs.right'
      : 'index.docs.right.noblog'
    return intl.messages[message].map((message, index) => {
      return printMessage(intl, message, index, intlExtras)
    })
  }

  return (
    <FullscreenWrapper>
      <LandingRow>
        <LandingRowContent>
          <H2>
            <FormattedMessage id='documentation' />
          </H2>
          <On laptop>
            <Row>
              <LeftColumn>
                <LeftText />
              </LeftColumn>
              <PanelGrid className='arcpanel_root'>
                <ARCMask />
              </PanelGrid>
            </Row>
          </On>
          <On laptop>
            <Row>
              <PanelGrid className='arcpanel_root'>
                <ARCRetouch />
              </PanelGrid>
              <RightColumn>
                <RightText />
              </RightColumn>
            </Row>
          </On>
          <On tablet mobile>
            <CenterColumn>
              <LeftText />
              <PanelGrid className='arcpanel_root'>
                <ARCMask />
              </PanelGrid>
              <RightText />
              <PanelGrid className='arcpanel_root'>
                <ARCRetouch />
              </PanelGrid>
            </CenterColumn>
          </On>
          <BuyButtons />
        </LandingRowContent>
      </LandingRow>
    </FullscreenWrapper>
  )
})
