import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import BuyButtons from '../buyButtons'
import { localizedUrl } from '@src/components/link'
import Linker from '@src/common/linker'
import config from '@src/common/config'
import { Link } from 'gatsby'
import {
  FullscreenWrapper,
  H2,
  H3,
  PM
} from './styles'
import { LongBlueButton } from '@components/buttons/style'
import {
  LandingRow,
  LandingRowContent,
  RowMobileCol,
  CenterColumn
} from '@src/style/globalStyle'

import { printMessage } from '@src/style/intlStyler'

export default injectIntl(({ intl: { locale } }) => {
  const intlExtras = {}
  return (
    <LandingRow grey>
      <LandingRowContent>
        <CenterColumn
          reverseCol
          style={{
            'paddingTop': '2rem'
          }}
        >
          <LongBlueButton
            onClick={() => {
              Linker.openUrl(localizedUrl('/luminosity-masking-basics', locale))
            }}
          >
            <FormattedMessage id='index.course' />
          </LongBlueButton>
        </CenterColumn>
      </LandingRowContent>
    </LandingRow>
  )
})
