import React from 'react'
import { injectIntl } from 'react-intl'
import { FullscreenWrapper } from '../styles'
import * as Styles from './styles'

import { graphql, useStaticQuery } from 'gatsby'
import Linker from '@src/common/linker'
import { useState } from 'react'
import { useMemo } from 'react'
import { useEffect } from 'react'

import { shuffleArray } from '@src/common/utils'

export default injectIntl(({ intl }) => {
  const [images, setImages] = useState()
  const galleryData = useStaticQuery(
    graphql`
      {
        images: allFile(
          filter: { absolutePath: { regex: "/^.*(_gallery.+).jpg$/" } }
        ) {
          group(field: relativeDirectory) {
            folder: fieldValue
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(
                    width: 786
                    placeholder: BLURRED
                    layout: CONSTRAINED
                  )
                }
                name
              }
            }
          }
        }
        meta: allFile(
          filter: { absolutePath: { regex: "/^.*(_gallery.+).json$/" } }
        ) {
          edges {
            node {
              childrenMetaJson {
                folder
                name
                link
              }
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    const images = galleryData.images.group.flatMap(group => {
      const { folder, edges } = group
      const folderImages = edges.map(edge => {
        const meta = galleryData.meta.edges[0].node.childrenMetaJson.filter(
          json => json.folder == folder
        )[0]
        return {
          gatsbyImageData: edge.node.childImageSharp.gatsbyImageData,
          link: meta.link,
          name: meta.name
        }
      })
      return folderImages
    })
    shuffleArray(images)
    setImages(images)
  }, [])

  if (!images || images.length == 0) return <></>

  return (
    <Styles.GalleryWrapper>
      <Styles.Masonry>
        {images.map((image, index) => {
          const ratio =
            image.gatsbyImageData.width / image.gatsbyImageData.height
          const width = 400 * ratio
          return (
            <Styles.ImageWrapper
              key={index}
              data-debug={JSON.stringify(image.fluid)}
              onClick={() => {
                Linker.openUrl(image.link)
              }}
              style={{
                width: `${width}px`
              }}
            >
              <Styles.Image alt='' image={image.gatsbyImageData} />
              <Styles.Author>
                <a target='_blank' href={image.link}>
                  {image.name}
                </a>
              </Styles.Author>
            </Styles.ImageWrapper>
          )
        })}
      </Styles.Masonry>
    </Styles.GalleryWrapper>
  )
})
