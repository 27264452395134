import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import BuyButtons from "../../buyButtons"
import { LocalizedLink, localizedUrl } from "@src/components/link"
import Linker from "@src/common/linker"
import config from "@src/common/config"
import { Link } from "gatsby"
import { FullscreenWrapper, H2, H3, PM, CardH3 } from "../styles"
import * as Style from "./styles"
import * as OStyle from "../freeVsPro/styles"
import * as CoreStyle from "@src/style/core"
import { BlueButton, YellowButton } from "@components/buttons/style"
import * as GlobalStyle from "@src/style/globalStyle"
import { ButtonsColumn, PhotoshopRequired } from "../../buyButtons/style"
import { StyledMessage, StyledMessages } from "@components/common/styledMessage"
import { PurchaseButton, SimplePurchaseButton } from "@src/components/buttons"

export default injectIntl(
  ({ intl: { locale }, image, title, description, link }) => {
    return (
      <Style.Card
        onClick={() => {
          Linker.openUrl(link)
        }}
      >
        <a href={link} target="_blank">
          <Style.CardContent>
            {image && <Style.Image image={image} />}
            <Style.CardText>
              <CardH3>{title}</CardH3>
              <PM>{description}</PM>
            </Style.CardText>
          </Style.CardContent>
        </a>
      </Style.Card>
    )
  }
)
