import React, { useState } from "react"
import { injectIntl } from "react-intl"
import { FullscreenWrapper } from "../styles"
import * as Styles from "./styles"
import * as GlobalStyle from "@src/style/globalStyle"
import * as MainStyles from "../styles"

import Linker from "@src/common/linker"

import { LongBlueButton } from "@components/buttons/style"
import {
  StyledMessage,
  StyledMessages,
} from "@src/components/common/styledMessage"

export default injectIntl(({ intl }) => {
  return (
    <MainStyles.FullscreenWrapper>
      <GlobalStyle.LandingRow>
        <GlobalStyle.LandingRowContent>
          <MainStyles.H2>
            <StyledMessage id="index.chat.title" />
          </MainStyles.H2>
          <StyledMessages id="index.chat" />
          <GlobalStyle.CenterColumn
            style={{
              paddingTop: "2rem",
            }}
          >
            <LongBlueButton
              onClick={() => {
                Linker.openUrl("https://discord.com/invite/u8a244zQ2S")
              }}
            >
              <StyledMessage id="index.chat.join" />
            </LongBlueButton>
          </GlobalStyle.CenterColumn>
        </GlobalStyle.LandingRowContent>
      </GlobalStyle.LandingRow>
    </MainStyles.FullscreenWrapper>
  )
})
