import styled, { css } from "styled-components"
import * as GlobalStyle from "@src/style/globalStyle"
import { device, core } from "@src/style"

export const Card = styled.div`
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0 1rem 1rem 0rem;
  width: 330px;
  height: 415px;
  transition: transform 0.2s !important;
  @media ${device.tablet} {
    margin-bottom: 0px;
    width: 450px;
  }
  &:hover {
    background: #fff5f0;
    transform: scale(0.99);
  }
`

export const ReviewsRow = styled(GlobalStyle.LandingRowContent)`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${device.laptopL} {
    align-items: start;
    flex-direction: row;
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const CardText = styled.div`
  height: 100%;
  padding: 1.5rem;
`

export const Image = styled.div`
  width: 100%;
  height: 200px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.image});
`
