import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import BuyButtons from "../../buyButtons"
import { LocalizedLink, localizedUrl } from "@src/components/link"
import Linker from "@src/common/linker"
import config from "@src/common/config"
import { Link } from "gatsby"
import { FullscreenWrapper, H2, H3, PM } from "../styles"
import * as Style from "./styles"
import ReviewCard from "./reviewCard"
import * as CoreStyle from "@src/style/core"
import { BlueButton, YellowButton } from "@components/buttons/style"
import * as GlobalStyle from "@src/style/globalStyle"
import { ButtonsColumn, PhotoshopRequired } from "../../buyButtons/style"
import { StyledMessage, StyledMessages } from "@components/common/styledMessage"
import { PurchaseButton, SimplePurchaseButton } from "@src/components/buttons"

export default injectIntl(({ intl }) => {
  return (
    <FullscreenWrapper>
      <GlobalStyle.LandingRow white>
        <GlobalStyle.LandingRowContent>
          <H2>
            <StyledMessage id="reviews" />
          </H2>
        </GlobalStyle.LandingRowContent>
        <Style.ReviewsRow>
          <ReviewCard
            title={intl.messages["reviews.fixthephoto.title"]}
            image="https://fixthephoto.com/images/content/arcpanel-color-grading.png"
            description={intl.messages["reviews.fixthephoto.desc"]}
            link="https://fixthephoto.com/arcpanel-review.html"
          />
          <ReviewCard
            title={intl.messages["reviews.1photo.1.title"]}
            image="https://school.1photo.tv/storage/mail/2018/thumb_700_188_mail.jpg"
            description={intl.messages["reviews.1photo.1.desc"]}
            link="https://school.1photo.tv/lesson/188?idx=1545562670"
          />
          <ReviewCard
            title={intl.messages["reviews.1photo.2.title"]}
            image="https://school.1photo.tv/storage/mail/2019/thumb_700_286_mail.jpg"
            description={intl.messages["reviews.1photo.2.desc"]}
            link="https://school.1photo.tv/lesson/286?idx=1545562670"
          />
        </Style.ReviewsRow>
      </GlobalStyle.LandingRow>
    </FullscreenWrapper>
  )
})
