import styled, { css } from "styled-components";
import * as GlobalStyle from "@src/style/globalStyle";
import { device, core } from "@src/style";

export const H2 = styled(core.H2)`
  margin-top: 36px;
`;

export const PL = styled(core.PL)`
  margin: 32px 0;
`;

export const Text = styled(core.PL)``;

export const UkraineRow = styled.div`
  width: 100%;
  margin: 0 auto;

  *:last-child {
    margin: 0 auto;
  }

  padding: 8px 0px;
  ${(props) =>
    props.blue &&
    css`
      background-color: #f5ce02cc;
      color: ${(props) => props.theme.main.rows.codeBar.textColor};
    `}
  ${(props) =>
    props.yellow &&
    css`
      background-color: #0158b5cc;
      color: ${(props) => props.theme.main.rows.codeBar.textColor};
    `}

  div {
    ${(props) => props.theme.widthLimiter}
    margin: 0 auto;
  }
`;

export const BlackFridayRow = styled.div`
  width: 100%;
  margin: 0 auto;

  *:last-child {
    margin: 0 auto;
  }

  padding: 8px 0px;
  ${(props) =>
    props.red &&
    css`
      background-color: rgb(226 0 0);
      color: ${(props) => props.theme.main.rows.codeBar.textColor};
    `}
  ${(props) =>
    props.black &&
    css`
      background-color: rgb(0 0 0 / 80%);
      color: ${(props) => props.theme.main.rows.codeBar.textColor};
    `}

  div {
    ${(props) => props.theme.widthLimiter}
    margin: 0 auto;
  }
`;

export const FullscreenWrapper = styled.div`
  width: 100%;
  color: white;
  background-color rgb(40,60,82);
`;

export const PromoImage = styled.img`
  width: 100%;
  transform: scaleX(-1);
`;

export const HalfBlock = styled.div`
  width: 100%;
`;

export const Square = styled.div`
  width: 50%;

  div {
    width: 100%;
    padding-bottom: 100%;
    position: relative;

    div {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
      }
    }
  }
`;
